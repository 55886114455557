import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  concat,
  split,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { fetchAuthSession } from 'aws-amplify/auth';
const apolloHttpLink = new HttpLink({
  uri: 'https://graphql.datadq.com/',
  cache: { ...new InMemoryCache({ addTypename: false }) },
});
const apolloWsLink = new GraphQLWsLink(
  createClient({
    url: 'ws://graphql.datadq.com/subscriptions',
  })
);
const authMiddleware = setContext((operation, { headers }) =>
  fetchAuthSession().then((data) => ({
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + data.tokens?.idToken?.toString(),
    },
  }))
);

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  apolloWsLink,
  apolloHttpLink
);

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({ addTypename: false }),
  link: concat(authMiddleware, splitLink),
});
